/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 39
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '69582314aaa3987c0834ac1763b242d4',
  development: '24218a5e12b501e3f6a49a581113c551',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '39',
    branch: 'main',
    source: 'web',
    versionId: '9ac66a9f-e570-4d90-b3fd-02b9678759f4',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & {
  environment: Environment;
  client?: { configuration?: BrowserOptions };
};
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | ^(FAQ)|(T&C)|(Privacy)|(Cookie Settings)$ |
   */
  footer_visited?: string;
  initial_dclid?: any;
  initial_fbclid?: any;
  initial_gbraid?: any;
  initial_gclid?: any;
  initial_ko_click_id?: any;
  initial_msclkid?: any;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_ttclid?: any;
  initial_twclid?: any;
  initial_utm_campaign?: any;
  initial_utm_content?: any;
  initial_utm_medium?: any;
  initial_utm_source?: any;
  initial_utm_term?: any;
  initial_wbraid?: any;
  market?: string;
  market_code?: string;
  orchestrator?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | ^(WAITLISTED)|(PENDING)|(ACTIVE)|(RETURN_PENDING)|(RETURNED)|(CLOSED)$ |
   */
  reservation_status?: string;
  user_azure_id?: string;
  weekly_pricing_rate?: string;
}

export interface ApplicationApprovalPageDurationProperties {
  time_spent: string;
}

export interface ConfirmationPageProperties {
  market_code: string;
}

export interface CreateReservationReachedWithMarketCodeProperties {
  market_code: string;
}

export interface CreditCardAndConfirmationPageDurationProperties {
  time_spent: string;
}

export interface DebitCardContractModalClosedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | IGNORED, ACCEPTED |
   */
  action: 'IGNORED' | 'ACCEPTED';
}

export interface DebitCardContractModalShownProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DASHBOARD, ADD_PAYMENT_METHOD |
   */
  screen_name: 'DASHBOARD' | 'ADD_PAYMENT_METHOD';
}

export interface ExperianAddressErrorOccurredProperties {
  market_code: string;
}

export interface ExperianCheckInProgressProperties {
  market_code: string;
}

export interface ExperianErrorReportedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex | ^(experian-address)|(experian-ssn)|(experian)$ |
   */
  error_type: string;
}

export interface ExperianGenericErrorOccurredProperties {
  market_code: string;
}

export interface ExperianSsnErrorOccurredProperties {
  market_code: string;
}

export interface ExperianSuccessProperties {
  market_code: string;
}

export interface PackageSelectionPageDurationProperties {
  time_spent: string;
}

export interface PackageSelectionTileButtonClickProperties {
  weekly_pricing_rate: string;
}

export interface PasswordAfterLoginCreateAccountErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  error_msg: string;
}

export interface PricingTierSelectedProperties {
  market_code: string;
  weekly_pricing_rate: string;
}

export interface PricingTierSelectedFromWaitlistFlowProperties {
  market_code: string;
  selected_tier: string;
}

export interface QualifiedAndPasswordCreationPageCreateAccountErrorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  error_msg: string;
}

export interface SignUpPageDurationProperties {
  time_spent: string;
}

export interface StripePaymentErrorReceivedProperties {
  stripe_error: string;
}

export interface UpdateAddressErrorPageDurationProperties {
  time_spent: string;
}

export interface UserMarketProperties {
  market_code: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class ApplicationApprovalPageDuration implements BaseEvent {
  event_type = 'application approval page duration';

  constructor(public event_properties: ApplicationApprovalPageDurationProperties) {
    this.event_properties = event_properties;
  }
}

export class ApplicationApprovalPageLoad implements BaseEvent {
  event_type = 'application approval page load';
}

export class ApplicationApprovalSubmitButtonClick implements BaseEvent {
  event_type = 'application approval submit button click';
}

export class ConfirmationPage implements BaseEvent {
  event_type = 'confirmation page';

  constructor(public event_properties: ConfirmationPageProperties) {
    this.event_properties = event_properties;
  }
}

export class CongratsModalLoaded implements BaseEvent {
  event_type = 'congrats modal loaded';
}

export class CreateReservationReachedWithMarketCode implements BaseEvent {
  event_type = 'create reservation reached with market code';

  constructor(public event_properties: CreateReservationReachedWithMarketCodeProperties) {
    this.event_properties = event_properties;
  }
}

export class CreditCardAddedOnYouAreApprovedPage implements BaseEvent {
  event_type = 'credit card added on you are approved page';
}

export class CreditCardAddedSuccessfullyDuringSignUp implements BaseEvent {
  event_type = 'credit card added successfully during sign up';
}

export class CreditCardAddedSuccessfullyOnDashboard implements BaseEvent {
  event_type = 'credit card added successfully on dashboard';
}

export class CreditCardAndConfirmationPageDuration implements BaseEvent {
  event_type = 'credit card and confirmation page duration';

  constructor(public event_properties: CreditCardAndConfirmationPageDurationProperties) {
    this.event_properties = event_properties;
  }
}

export class CreditCardAndConfirmationPageLoad implements BaseEvent {
  event_type = 'credit card and confirmation page load';
}

export class CreditCardAndConfirmationPageNextButtonClick implements BaseEvent {
  event_type = 'credit card and confirmation page next button click';
}

export class CreditCardTileButtonClick implements BaseEvent {
  event_type = 'credit card tile button click';
}

export class CreditCheckGenericErrorContactUsButtonClick implements BaseEvent {
  event_type = 'credit check generic error contact us button click';
}

export class CreditCheckGenericErrorPageLoad implements BaseEvent {
  event_type = 'credit check generic error page load';
}

export class CreditFreezeContactExperianButtonClick implements BaseEvent {
  event_type = 'credit freeze contact experian button click';
}

export class CreditFreezePageLoad implements BaseEvent {
  event_type = 'credit freeze page load';
}

export class DebitCardContractModalClosed implements BaseEvent {
  event_type = 'debit card contract modal closed';

  constructor(public event_properties: DebitCardContractModalClosedProperties) {
    this.event_properties = event_properties;
  }
}

export class DebitCardContractModalShown implements BaseEvent {
  event_type = 'debit card contract modal shown';

  constructor(public event_properties: DebitCardContractModalShownProperties) {
    this.event_properties = event_properties;
  }
}

export class EnterpriseCheckYesContinue implements BaseEvent {
  event_type = 'enterprise check yes continue';
}

export class EnterpriseGoBack implements BaseEvent {
  event_type = 'enterprise go back';
}

export class ExperianAddressErrorOccurred implements BaseEvent {
  event_type = 'experian address error occurred';

  constructor(public event_properties: ExperianAddressErrorOccurredProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperianCheckInProgress implements BaseEvent {
  event_type = 'experian check in progress';

  constructor(public event_properties: ExperianCheckInProgressProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperianErrorReported implements BaseEvent {
  event_type = 'experian error reported';

  constructor(public event_properties: ExperianErrorReportedProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperianGenericErrorOccurred implements BaseEvent {
  event_type = 'experian generic error occurred';

  constructor(public event_properties: ExperianGenericErrorOccurredProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperianSsnErrorOccurred implements BaseEvent {
  event_type = 'experian ssn error occurred';

  constructor(public event_properties: ExperianSsnErrorOccurredProperties) {
    this.event_properties = event_properties;
  }
}

export class ExperianSuccess implements BaseEvent {
  event_type = 'experian success';

  constructor(public event_properties: ExperianSuccessProperties) {
    this.event_properties = event_properties;
  }
}

export class FooterLinks implements BaseEvent {
  event_type = 'footer links';
}

export class GetStartedButtonClick implements BaseEvent {
  event_type = 'get started button click';
}

export class HomescreenViewed implements BaseEvent {
  event_type = 'homescreen viewed';
}

export class Invoice implements BaseEvent {
  event_type = 'invoice';
}

export class LeaseButtonClick implements BaseEvent {
  event_type = 'lease button click';
}

export class LoginPageViewed implements BaseEvent {
  event_type = 'login page viewed';
}

export class Logout implements BaseEvent {
  event_type = 'logout';
}

export class LowFicoScoreContactUsButtonClick implements BaseEvent {
  event_type = 'low fico score contact us button click';
}

export class LowFicoScorePageLoad implements BaseEvent {
  event_type = 'low fico score page load';
}

export class PackageSelectionPageDuration implements BaseEvent {
  event_type = 'package selection page duration';

  constructor(public event_properties: PackageSelectionPageDurationProperties) {
    this.event_properties = event_properties;
  }
}

export class PackageSelectionPageLoad implements BaseEvent {
  event_type = 'package selection page load';
}

export class PackageSelectionTileButtonClick implements BaseEvent {
  event_type = 'package selection tile button click';

  constructor(public event_properties: PackageSelectionTileButtonClickProperties) {
    this.event_properties = event_properties;
  }
}

export class PasswordAfterLoginCreateAccountButtonClick implements BaseEvent {
  event_type = 'password after login create account button click';
}

export class PasswordAfterLoginCreateAccountError implements BaseEvent {
  event_type = 'password after login create account error';

  constructor(public event_properties: PasswordAfterLoginCreateAccountErrorProperties) {
    this.event_properties = event_properties;
  }
}

export class PasswordCreatedSuccessfullyDuringSignUp implements BaseEvent {
  event_type = 'password created successfully during sign up';
}

export class PasswordCreatedSuccessfullyOnDashboard implements BaseEvent {
  event_type = 'password created successfully on dashboard';
}

export class PasswordCreationAfterLoginPageLoad implements BaseEvent {
  event_type = 'password creation after login page load';
}

export class PaymentMethods implements BaseEvent {
  event_type = 'payment methods';
}

export class PricingTierSelected implements BaseEvent {
  event_type = 'pricing tier selected';

  constructor(public event_properties: PricingTierSelectedProperties) {
    this.event_properties = event_properties;
  }
}

export class PricingTierSelectedFromWaitlistFlow implements BaseEvent {
  event_type = 'pricing tier selected from waitlist flow';

  constructor(public event_properties: PricingTierSelectedFromWaitlistFlowProperties) {
    this.event_properties = event_properties;
  }
}

export class QualifiedAndPasswordCreationPageCreateAccountButtonClick implements BaseEvent {
  event_type = 'qualified and password creation page create account button click';
}

export class QualifiedAndPasswordCreationPageCreateAccountError implements BaseEvent {
  event_type = 'qualified and password creation page create account error';

  constructor(public event_properties: QualifiedAndPasswordCreationPageCreateAccountErrorProperties) {
    this.event_properties = event_properties;
  }
}

export class QualifiedAndPasswordCreationPageLoad implements BaseEvent {
  event_type = 'qualified and password creation page load';
}

export class RentalButtonClick implements BaseEvent {
  event_type = 'rental button click';
}

export class SignInButtonClickFromSignUpPage implements BaseEvent {
  event_type = 'sign in button click from sign up page';
}

export class SignUpPageDuration implements BaseEvent {
  event_type = 'sign up page duration';

  constructor(public event_properties: SignUpPageDurationProperties) {
    this.event_properties = event_properties;
  }
}

export class SignUpPageLoad implements BaseEvent {
  event_type = 'sign up page load';
}

export class SsnContinueButtonClick implements BaseEvent {
  event_type = 'ssn continue button click';
}

export class SsnInputPageLoad implements BaseEvent {
  event_type = 'ssn input page load';
}

export class StripePaymentErrorReceived implements BaseEvent {
  event_type = 'stripe payment error received';

  constructor(public event_properties: StripePaymentErrorReceivedProperties) {
    this.event_properties = event_properties;
  }
}

export class SupportGuideLinks implements BaseEvent {
  event_type = 'support guide links';
}

export class UpdateAddressErrorPageDuration implements BaseEvent {
  event_type = 'update address error page duration';

  constructor(public event_properties: UpdateAddressErrorPageDurationProperties) {
    this.event_properties = event_properties;
  }
}

export class UpdateAddressErrorPageLoad implements BaseEvent {
  event_type = 'update address error page load';
}

export class UpdateAddressErrorSubmitButtonClick implements BaseEvent {
  event_type = 'update address error submit button click';
}

export class UserClickedLetsGoButtonInApprovedPage implements BaseEvent {
  event_type = 'user clicked lets go button in approved page';
}

export class UserMarket implements BaseEvent {
  event_type = 'user market';

  constructor(public event_properties: UserMarketProperties) {
    this.event_properties = event_properties;
  }
}

export class ViewProfile implements BaseEvent {
  event_type = 'view profile';
}

export class YouAreApprovedPageLoad implements BaseEvent {
  event_type = 'you are approved page load';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = options.client && 'configuration' in options.client ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, {...DefaultConfiguration, ...configuration});
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(userId: string | undefined, properties?: IdentifyProperties, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Flush the event.
   */
  flush(): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * application approval page duration
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/application%20approval%20page%20duration)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. time_spent)
   * @param options Amplitude event options.
   */
  applicationApprovalPageDuration(properties: ApplicationApprovalPageDurationProperties, options?: EventOptions) {
    return this.track(new ApplicationApprovalPageDuration(properties), options);
  }

  /**
   * application approval page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/application%20approval%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  applicationApprovalPageLoad(options?: EventOptions) {
    return this.track(new ApplicationApprovalPageLoad(), options);
  }

  /**
   * application approval submit button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/application%20approval%20submit%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  applicationApprovalSubmitButtonClick(options?: EventOptions) {
    return this.track(new ApplicationApprovalSubmitButtonClick(), options);
  }

  /**
   * confirmation page
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/confirmation%20page)
   *
   * Owner: David Subedi
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  confirmationPage(properties: ConfirmationPageProperties, options?: EventOptions) {
    return this.track(new ConfirmationPage(properties), options);
  }

  /**
   * congrats modal loaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/congrats%20modal%20loaded)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  congratsModalLoaded(options?: EventOptions) {
    return this.track(new CongratsModalLoaded(), options);
  }

  /**
   * create reservation reached with market code
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/create%20reservation%20reached%20with%20market%20code)
   *
   * Owner: Andrew Fox
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  createReservationReachedWithMarketCode(
    properties: CreateReservationReachedWithMarketCodeProperties,
    options?: EventOptions
  ) {
    return this.track(new CreateReservationReachedWithMarketCode(properties), options);
  }

  /**
   * credit card added on you are approved page
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20card%20added%20on%20you%20are%20approved%20page)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditCardAddedOnYouAreApprovedPage(options?: EventOptions) {
    return this.track(new CreditCardAddedOnYouAreApprovedPage(), options);
  }

  /**
   * credit card added successfully during sign up
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20card%20added%20successfully%20during%20sign%20up)
   *
   * When user adds credit card successfully during sign up flow
   *
   * @param options Amplitude event options.
   */
  creditCardAddedSuccessfullyDuringSignUp(options?: EventOptions) {
    return this.track(new CreditCardAddedSuccessfullyDuringSignUp(), options);
  }

  /**
   * credit card added successfully on dashboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20card%20added%20successfully%20on%20dashboard)
   *
   * When user adds credit card successfully after landing on dashboard
   *
   * @param options Amplitude event options.
   */
  creditCardAddedSuccessfullyOnDashboard(options?: EventOptions) {
    return this.track(new CreditCardAddedSuccessfullyOnDashboard(), options);
  }

  /**
   * credit card and confirmation page duration
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20card%20and%20confirmation%20page%20duration)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. time_spent)
   * @param options Amplitude event options.
   */
  creditCardAndConfirmationPageDuration(
    properties: CreditCardAndConfirmationPageDurationProperties,
    options?: EventOptions
  ) {
    return this.track(new CreditCardAndConfirmationPageDuration(properties), options);
  }

  /**
   * credit card and confirmation page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20card%20and%20confirmation%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditCardAndConfirmationPageLoad(options?: EventOptions) {
    return this.track(new CreditCardAndConfirmationPageLoad(), options);
  }

  /**
   * credit card and confirmation page next button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20card%20and%20confirmation%20page%20next%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditCardAndConfirmationPageNextButtonClick(options?: EventOptions) {
    return this.track(new CreditCardAndConfirmationPageNextButtonClick(), options);
  }

  /**
   * credit card tile button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20card%20tile%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditCardTileButtonClick(options?: EventOptions) {
    return this.track(new CreditCardTileButtonClick(), options);
  }

  /**
   * credit check generic error contact us button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20check%20generic%20error%20contact%20us%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditCheckGenericErrorContactUsButtonClick(options?: EventOptions) {
    return this.track(new CreditCheckGenericErrorContactUsButtonClick(), options);
  }

  /**
   * credit check generic error page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20check%20generic%20error%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditCheckGenericErrorPageLoad(options?: EventOptions) {
    return this.track(new CreditCheckGenericErrorPageLoad(), options);
  }

  /**
   * credit freeze contact experian button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20freeze%20contact%20experian%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditFreezeContactExperianButtonClick(options?: EventOptions) {
    return this.track(new CreditFreezeContactExperianButtonClick(), options);
  }

  /**
   * credit freeze page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/credit%20freeze%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  creditFreezePageLoad(options?: EventOptions) {
    return this.track(new CreditFreezePageLoad(), options);
  }

  /**
   * debit card contract modal closed
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/debit%20card%20contract%20modal%20closed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. action)
   * @param options Amplitude event options.
   */
  debitCardContractModalClosed(properties: DebitCardContractModalClosedProperties, options?: EventOptions) {
    return this.track(new DebitCardContractModalClosed(properties), options);
  }

  /**
   * debit card contract modal shown
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/debit%20card%20contract%20modal%20shown)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. screen_name)
   * @param options Amplitude event options.
   */
  debitCardContractModalShown(properties: DebitCardContractModalShownProperties, options?: EventOptions) {
    return this.track(new DebitCardContractModalShown(properties), options);
  }

  /**
   * enterprise check yes continue
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/enterprise%20check%20yes%20continue)
   *
   * Owner: rmcdevi2@ford.com
   *
   * @param options Amplitude event options.
   */
  enterpriseCheckYesContinue(options?: EventOptions) {
    return this.track(new EnterpriseCheckYesContinue(), options);
  }

  /**
   * enterprise go back
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/enterprise%20go%20back)
   *
   * Owner: rmcdevi2@ford.com
   *
   * @param options Amplitude event options.
   */
  enterpriseGoBack(options?: EventOptions) {
    return this.track(new EnterpriseGoBack(), options);
  }

  /**
   * experian address error occurred
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/experian%20address%20error%20occurred)
   *
   * Owner: David Subedi
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  experianAddressErrorOccurred(properties: ExperianAddressErrorOccurredProperties, options?: EventOptions) {
    return this.track(new ExperianAddressErrorOccurred(properties), options);
  }

  /**
   * experian check in progress
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/experian%20check%20in%20progress)
   *
   * Owner: bsalhuan@ford.com
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  experianCheckInProgress(properties: ExperianCheckInProgressProperties, options?: EventOptions) {
    return this.track(new ExperianCheckInProgress(properties), options);
  }

  /**
   * experian error reported
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/experian%20error%20reported)
   *
   * Owner: bsalhuan@ford.com
   *
   * @param properties The event's properties (e.g. error_type)
   * @param options Amplitude event options.
   */
  experianErrorReported(properties: ExperianErrorReportedProperties, options?: EventOptions) {
    return this.track(new ExperianErrorReported(properties), options);
  }

  /**
   * experian generic error occurred
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/experian%20generic%20error%20occurred)
   *
   * Owner: David Subedi
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  experianGenericErrorOccurred(properties: ExperianGenericErrorOccurredProperties, options?: EventOptions) {
    return this.track(new ExperianGenericErrorOccurred(properties), options);
  }

  /**
   * experian ssn error occurred
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/experian%20ssn%20error%20occurred)
   *
   * Owner: David Subedi
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  experianSsnErrorOccurred(properties: ExperianSsnErrorOccurredProperties, options?: EventOptions) {
    return this.track(new ExperianSsnErrorOccurred(properties), options);
  }

  /**
   * experian success
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/experian%20success)
   *
   * Owner: bsalhuan@ford.com
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  experianSuccess(properties: ExperianSuccessProperties, options?: EventOptions) {
    return this.track(new ExperianSuccess(properties), options);
  }

  /**
   * footer links
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/footer%20links)
   *
   * when they click on footer links component on dashboard
   *
   * Owner: Rahul R Pillai
   *
   * @param options Amplitude event options.
   */
  footerLinks(options?: EventOptions) {
    return this.track(new FooterLinks(), options);
  }

  /**
   * get started button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/get%20started%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  getStartedButtonClick(options?: EventOptions) {
    return this.track(new GetStartedButtonClick(), options);
  }

  /**
   * homescreen viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/homescreen%20viewed)
   *
   * when they land on /dashboard what is their reservation status
   *
   * Owner: Rahul R Pillai
   *
   * @param options Amplitude event options.
   */
  homescreenViewed(options?: EventOptions) {
    return this.track(new HomescreenViewed(), options);
  }

  /**
   * invoice
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/invoice)
   *
   * when they click on invoice component on dashboard, to view current/old invoices
   *
   * Owner: Rahul R Pillai
   *
   * @param options Amplitude event options.
   */
  invoice(options?: EventOptions) {
    return this.track(new Invoice(), options);
  }

  /**
   * lease button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/lease%20button%20click)
   *
   * Owner: mshioza1@ford.com
   *
   * @param options Amplitude event options.
   */
  leaseButtonClick(options?: EventOptions) {
    return this.track(new LeaseButtonClick(), options);
  }

  /**
   * login page viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/login%20page%20viewed)
   *
   * Owner: bsalhuan@ford.com
   *
   * @param options Amplitude event options.
   */
  loginPageViewed(options?: EventOptions) {
    return this.track(new LoginPageViewed(), options);
  }

  /**
   * logout
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/logout)
   *
   * when they click on logout
   *
   * Owner: Rahul R Pillai
   *
   * @param options Amplitude event options.
   */
  logout(options?: EventOptions) {
    return this.track(new Logout(), options);
  }

  /**
   * low fico score contact us button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/low%20fico%20score%20contact%20us%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  lowFicoScoreContactUsButtonClick(options?: EventOptions) {
    return this.track(new LowFicoScoreContactUsButtonClick(), options);
  }

  /**
   * low fico score page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/low%20fico%20score%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  lowFicoScorePageLoad(options?: EventOptions) {
    return this.track(new LowFicoScorePageLoad(), options);
  }

  /**
   * package selection page duration
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/package%20selection%20page%20duration)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. time_spent)
   * @param options Amplitude event options.
   */
  packageSelectionPageDuration(properties: PackageSelectionPageDurationProperties, options?: EventOptions) {
    return this.track(new PackageSelectionPageDuration(properties), options);
  }

  /**
   * package selection page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/package%20selection%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  packageSelectionPageLoad(options?: EventOptions) {
    return this.track(new PackageSelectionPageLoad(), options);
  }

  /**
   * package selection tile button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/package%20selection%20tile%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. weekly_pricing_rate)
   * @param options Amplitude event options.
   */
  packageSelectionTileButtonClick(properties: PackageSelectionTileButtonClickProperties, options?: EventOptions) {
    return this.track(new PackageSelectionTileButtonClick(properties), options);
  }

  /**
   * password after login create account button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/password%20after%20login%20create%20account%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  passwordAfterLoginCreateAccountButtonClick(options?: EventOptions) {
    return this.track(new PasswordAfterLoginCreateAccountButtonClick(), options);
  }

  /**
   * password after login create account error
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/password%20after%20login%20create%20account%20error)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. error_msg)
   * @param options Amplitude event options.
   */
  passwordAfterLoginCreateAccountError(
    properties: PasswordAfterLoginCreateAccountErrorProperties,
    options?: EventOptions
  ) {
    return this.track(new PasswordAfterLoginCreateAccountError(properties), options);
  }

  /**
   * password created successfully during sign up
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/password%20created%20successfully%20during%20sign%20up)
   *
   * When user creates password successfully during signup flow
   *
   * @param options Amplitude event options.
   */
  passwordCreatedSuccessfullyDuringSignUp(options?: EventOptions) {
    return this.track(new PasswordCreatedSuccessfullyDuringSignUp(), options);
  }

  /**
   * password created successfully on dashboard
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/password%20created%20successfully%20on%20dashboard)
   *
   * when user create a new password after landing on dashboard
   *
   * @param options Amplitude event options.
   */
  passwordCreatedSuccessfullyOnDashboard(options?: EventOptions) {
    return this.track(new PasswordCreatedSuccessfullyOnDashboard(), options);
  }

  /**
   * password creation after login page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/password%20creation%20after%20login%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  passwordCreationAfterLoginPageLoad(options?: EventOptions) {
    return this.track(new PasswordCreationAfterLoginPageLoad(), options);
  }

  /**
   * payment methods
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/payment%20methods)
   *
   * when they click on payments component on dashboard
   *
   * Owner: Rahul R Pillai
   *
   * @param options Amplitude event options.
   */
  paymentMethods(options?: EventOptions) {
    return this.track(new PaymentMethods(), options);
  }

  /**
   * pricing tier selected
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/pricing%20tier%20selected)
   *
   * Owner: David Subedi
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  pricingTierSelected(properties: PricingTierSelectedProperties, options?: EventOptions) {
    return this.track(new PricingTierSelected(properties), options);
  }

  /**
   * pricing tier selected from waitlist flow
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/pricing%20tier%20selected%20from%20waitlist%20flow)
   *
   * Owner: bsalhuan@ford.com
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  pricingTierSelectedFromWaitlistFlow(
    properties: PricingTierSelectedFromWaitlistFlowProperties,
    options?: EventOptions
  ) {
    return this.track(new PricingTierSelectedFromWaitlistFlow(properties), options);
  }

  /**
   * qualified and password creation page create account button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/qualified%20and%20password%20creation%20page%20create%20account%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  qualifiedAndPasswordCreationPageCreateAccountButtonClick(options?: EventOptions) {
    return this.track(new QualifiedAndPasswordCreationPageCreateAccountButtonClick(), options);
  }

  /**
   * qualified and password creation page create account error
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/qualified%20and%20password%20creation%20page%20create%20account%20error)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. error_msg)
   * @param options Amplitude event options.
   */
  qualifiedAndPasswordCreationPageCreateAccountError(
    properties: QualifiedAndPasswordCreationPageCreateAccountErrorProperties,
    options?: EventOptions
  ) {
    return this.track(new QualifiedAndPasswordCreationPageCreateAccountError(properties), options);
  }

  /**
   * qualified and password creation page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/qualified%20and%20password%20creation%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  qualifiedAndPasswordCreationPageLoad(options?: EventOptions) {
    return this.track(new QualifiedAndPasswordCreationPageLoad(), options);
  }

  /**
   * rental button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/rental%20button%20click)
   *
   * Owner: mshioza1@ford.com
   *
   * @param options Amplitude event options.
   */
  rentalButtonClick(options?: EventOptions) {
    return this.track(new RentalButtonClick(), options);
  }

  /**
   * sign in button click from sign up page
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/sign%20in%20button%20click%20from%20sign%20up%20page)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signInButtonClickFromSignUpPage(options?: EventOptions) {
    return this.track(new SignInButtonClickFromSignUpPage(), options);
  }

  /**
   * sign up page duration
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/sign%20up%20page%20duration)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. time_spent)
   * @param options Amplitude event options.
   */
  signUpPageDuration(properties: SignUpPageDurationProperties, options?: EventOptions) {
    return this.track(new SignUpPageDuration(properties), options);
  }

  /**
   * sign up page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/sign%20up%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signUpPageLoad(options?: EventOptions) {
    return this.track(new SignUpPageLoad(), options);
  }

  /**
   * ssn continue button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/ssn%20continue%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  ssnContinueButtonClick(options?: EventOptions) {
    return this.track(new SsnContinueButtonClick(), options);
  }

  /**
   * ssn input page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/ssn%20input%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  ssnInputPageLoad(options?: EventOptions) {
    return this.track(new SsnInputPageLoad(), options);
  }

  /**
   * stripe payment error received
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/stripe%20payment%20error%20received)
   *
   * Owner: bsalhuan@ford.com
   *
   * @param properties The event's properties (e.g. stripe_error)
   * @param options Amplitude event options.
   */
  stripePaymentErrorReceived(properties: StripePaymentErrorReceivedProperties, options?: EventOptions) {
    return this.track(new StripePaymentErrorReceived(properties), options);
  }

  /**
   * support guide links
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/support%20guide%20links)
   *
   * when they click on support guide link on dashboard
   *
   * Owner: Rahul R Pillai
   *
   * @param options Amplitude event options.
   */
  supportGuideLinks(options?: EventOptions) {
    return this.track(new SupportGuideLinks(), options);
  }

  /**
   * update address error page duration
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/update%20address%20error%20page%20duration)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. time_spent)
   * @param options Amplitude event options.
   */
  updateAddressErrorPageDuration(properties: UpdateAddressErrorPageDurationProperties, options?: EventOptions) {
    return this.track(new UpdateAddressErrorPageDuration(properties), options);
  }

  /**
   * update address error page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/update%20address%20error%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  updateAddressErrorPageLoad(options?: EventOptions) {
    return this.track(new UpdateAddressErrorPageLoad(), options);
  }

  /**
   * update address error submit button click
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/update%20address%20error%20submit%20button%20click)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  updateAddressErrorSubmitButtonClick(options?: EventOptions) {
    return this.track(new UpdateAddressErrorSubmitButtonClick(), options);
  }

  /**
   * user clicked lets go button in approved page
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/user%20clicked%20lets%20go%20button%20in%20approved%20page)
   *
   * When user clicked let's go button on Approved Page after reservation creation
   *
   * @param options Amplitude event options.
   */
  userClickedLetsGoButtonInApprovedPage(options?: EventOptions) {
    return this.track(new UserClickedLetsGoButtonInApprovedPage(), options);
  }

  /**
   * user market
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/user%20market)
   *
   * Owner: Akshaya Ramanarayanan
   *
   * @param properties The event's properties (e.g. market_code)
   * @param options Amplitude event options.
   */
  userMarket(properties: UserMarketProperties, options?: EventOptions) {
    return this.track(new UserMarket(properties), options);
  }

  /**
   * view profile
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/view%20profile)
   *
   * Profile viewed
   *
   * Owner: bsalhuan@ford.com
   *
   * @param options Amplitude event options.
   */
  viewProfile(options?: EventOptions) {
    return this.track(new ViewProfile(), options);
  }

  /**
   * you are approved page load
   *
   * [View in Tracking Plan](https://data.amplitude.com/forddrive/Driver%20Web%20Portal/events/main/latest/you%20are%20approved%20page%20load)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  youAreApprovedPageLoad(options?: EventOptions) {
    return this.track(new YouAreApprovedPageLoad(), options);
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
